import React from "react"

const Logo = () => (
  <svg
    width="114.14"
    height="31.96"
    viewBox="0 0 114.14 31.96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0,34.63l2.82-23H8.4l-2.82,23Zm6.35-26a3.57,3.57,0,0,1-1.62-.36A2.86,2.86,0,0,1,3.1,5.64a2.71,2.71,0,0,1,.43-1.53A3.07,3.07,0,0,1,4.7,3.05a3.52,3.52,0,0,1,1.65-.38A3.56,3.56,0,0,1,8,3a3,3,0,0,1,1.17,1,2.91,2.91,0,0,1,.43,1.55,3,3,0,0,1-.43,1.61A2.76,2.76,0,0,1,8,8.29,3.76,3.76,0,0,1,6.35,8.65Z"
      transform="translate(0 -2.67)"
      fill="#008e76"
    />
    <path
      d="M18.88,27.59a9.83,9.83,0,0,1-4.54-1,7.07,7.07,0,0,1-3-2.9,7.88,7.88,0,0,1-.89-4.43,7.9,7.9,0,0,1,1.38-4.2,8.42,8.42,0,0,1,3.31-2.82,10.4,10.4,0,0,1,4.61-1,9.62,9.62,0,0,1,4.52,1,7.3,7.3,0,0,1,3.06,2.89,7.71,7.71,0,0,1,.91,4.36,8.07,8.07,0,0,1-1.4,4.31,8.2,8.2,0,0,1-3.33,2.8A10.62,10.62,0,0,1,18.88,27.59ZM19,23a3.55,3.55,0,0,0,1.83-.46,3.47,3.47,0,0,0,1.29-1.29,4.23,4.23,0,0,0,.54-1.94,4,4,0,0,0-.31-1.79,2.67,2.67,0,0,0-1.07-1.23,3.2,3.2,0,0,0-1.72-.45,3.61,3.61,0,0,0-1.83.47,3.57,3.57,0,0,0-1.28,1.28,4.3,4.3,0,0,0-.55,1.88,4.23,4.23,0,0,0,.33,1.86,2.66,2.66,0,0,0,1.07,1.24A3.18,3.18,0,0,0,19,23Z"
      transform="translate(0 -2.67)"
      fill="#008e76"
    />
    <path
      d="M37.91,27.19l1.92-15.57h5.49L45,13.7H45a9.11,9.11,0,0,1,1.48-1.36,5.69,5.69,0,0,1,1.61-.82,6.18,6.18,0,0,1,2-.3A4.73,4.73,0,0,1,53,12a4.28,4.28,0,0,1,1.6,2.34,9.28,9.28,0,0,1,.19,3.77l-1.12,9.05H48.05l1-8.37a5.89,5.89,0,0,0,0-1.6,1.65,1.65,0,0,0-.53-1,1.69,1.69,0,0,0-1.11-.34,2.71,2.71,0,0,0-1.43.37,2.78,2.78,0,0,0-1,1.09,5.89,5.89,0,0,0-.53,1.86l-1,8Z"
      transform="translate(0 -2.67)"
      fill="#00bfa5"
    />
    <path
      d="M65.5,27.59a9.47,9.47,0,0,1-4.4-1,6.71,6.71,0,0,1-2.93-2.89,8.08,8.08,0,0,1-.79-4.52A8.36,8.36,0,0,1,58.73,15a8.18,8.18,0,0,1,3.15-2.79,9.43,9.43,0,0,1,4.34-1,8.35,8.35,0,0,1,4.24,1,6.59,6.59,0,0,1,2.73,2.94A9.67,9.67,0,0,1,74,19.78a.78.78,0,0,1,0,.14.71.71,0,0,0,0,.14,2.27,2.27,0,0,1-.07.46l-12.05-.06.06-3.07,6.69-.06a2.75,2.75,0,0,0-.34-1.27,2,2,0,0,0-.85-.85,2.62,2.62,0,0,0-1.29-.3,2.81,2.81,0,0,0-1.35.33,2.77,2.77,0,0,0-1,.9,3.43,3.43,0,0,0-.56,1.28l-.37,3a4.71,4.71,0,0,0,.34,1.86,2.63,2.63,0,0,0,1,1.22,2.68,2.68,0,0,0,1.49.42,2.59,2.59,0,0,0,1.61-.5,4.57,4.57,0,0,0,1.24-1.48l5,1a7.87,7.87,0,0,1-1.94,2.63,7.63,7.63,0,0,1-2.74,1.57A10.74,10.74,0,0,1,65.5,27.59Z"
      transform="translate(0 -2.67)"
      fill="#00bfa5"
    />
    <path
      d="M81.25,27.53a10.31,10.31,0,0,1-3.67-.62,8.29,8.29,0,0,1-2.72-1.64l2.73-3.38a8.91,8.91,0,0,0,1.89,1.3,4,4,0,0,0,1.74.43,1.7,1.7,0,0,0,.68-.11,1.08,1.08,0,0,0,.47-.35,1.16,1.16,0,0,0,.21-.59,1.06,1.06,0,0,0-.15-.68,2,2,0,0,0-.62-.55,9,9,0,0,0-1.24-.57,7.64,7.64,0,0,1-2.25-1.15A3.88,3.88,0,0,1,77.05,18a4.52,4.52,0,0,1-.26-2.22,4.5,4.5,0,0,1,1.1-2.54,5.5,5.5,0,0,1,2.32-1.52,9.46,9.46,0,0,1,3.15-.5,9.15,9.15,0,0,1,2.85.47A6,6,0,0,1,88.5,13l-2.6,3.16a4.81,4.81,0,0,0-1.22-.79A3.51,3.51,0,0,0,83.23,15a1.78,1.78,0,0,0-.91.2.7.7,0,0,0-.39.6.69.69,0,0,0,.16.54,1.91,1.91,0,0,0,.65.48,11.5,11.5,0,0,0,1.42.62,7.49,7.49,0,0,1,2.22,1.17,4.12,4.12,0,0,1,1.3,1.69A4.47,4.47,0,0,1,88,22.63a5,5,0,0,1-1,2.74A5.16,5.16,0,0,1,84.6,27,9.69,9.69,0,0,1,81.25,27.53Z"
      transform="translate(0 -2.67)"
      fill="#00bfa5"
    />
    <path
      d="M98.42,27.59a9.25,9.25,0,0,1-4.51-1.05,7,7,0,0,1-2.88-3,8.16,8.16,0,0,1-.76-4.45A8.52,8.52,0,0,1,91.57,15a8,8,0,0,1,3.07-2.81,9.29,9.29,0,0,1,4.37-1,9.82,9.82,0,0,1,2.5.33,9.62,9.62,0,0,1,2.09.76l-.37,4.93a5.93,5.93,0,0,0-1.63-1.1,4.3,4.3,0,0,0-1.81-.39,3.88,3.88,0,0,0-1.94.48,3.66,3.66,0,0,0-1.36,1.32,4.18,4.18,0,0,0-.61,1.86,3.79,3.79,0,0,0,.27,1.87,2.68,2.68,0,0,0,1.13,1.29,3.52,3.52,0,0,0,1.86.46,4.85,4.85,0,0,0,1.86-.35,9,9,0,0,0,1.86-1.14l-.53,5.21a6.92,6.92,0,0,1-1.8.62A9.75,9.75,0,0,1,98.42,27.59Z"
      transform="translate(0 -2.67)"
      fill="#00bfa5"
    />
    <path
      d="M105.43,27.19l1.92-15.57h5.58L111,27.19Zm5.46-18.54a3.62,3.62,0,0,1-1.63-.36,2.86,2.86,0,0,1-1.63-2.65,2.79,2.79,0,0,1,.43-1.53,3.07,3.07,0,0,1,1.17-1.06,3.56,3.56,0,0,1,1.66-.38,3.52,3.52,0,0,1,1.65.37,3,3,0,0,1,1.17,1,2.83,2.83,0,0,1,.43,1.55,2.91,2.91,0,0,1-.43,1.61,2.76,2.76,0,0,1-1.18,1A3.72,3.72,0,0,1,110.89,8.65Z"
      transform="translate(0 -2.67)"
      fill="#00bfa5"
    />
  </svg>
)

export default Logo
